import React, { FC, useEffect, useState } from 'react'

import {
  ExclamationTriangleIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { PROD_CLIENT } from 'api/src/common/enums'
import { GlobalRole } from 'types/graphql'

import DebounceNumberInput from 'src/components/DebounceNumberInput/DebounceNumberInput'
import DebounceTextInput from 'src/components/DebounceTextInput/DebounceTextInput'
import CoursePreview from 'src/components/Learner/Courses/CoursePreview/CoursePreview'
import Accordion from 'src/components/Library/Accordion/Accordion'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'
import Switch from 'src/components/Library/Switch/Switch'
import Tag from 'src/components/Tag/Tag'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'
import { useAuth } from 'src/Providers'

import {
  DateTypes,
  ErrorTypes,
  ProgressionTypeDefinitions,
  ProgressionTypes,
  ProgressionTypesLabels,
  StartEndTriggerTypes,
  StartEndTriggerTypesDefinitions,
  StartEndTriggerTypeTitles,
} from '../constants'
import {
  configureFormFields,
  getCurrentCourse,
  getMilestoneOptions,
} from '../helpers'
import {
  FormOptions,
  ImproverGoalMemberTemplateEditFormProps,
} from '../interfaces'

const allRoles: GlobalRole[] = ['SUPERADMIN', 'STAFFLINK', 'CUSTOMER']

export const FormSpacer: FC<{ size?: number }> = ({ size = 2 }) => (
  <div className={`py-${size}`}></div>
)

export const FormInputRow: FC<{
  label?: React.ReactNode
  labelTag?: React.ReactNode
  infoTag?: string
  children: JSX.Element
  dataTestId?: string
}> = ({ label, infoTag, labelTag, children, dataTestId }) => {
  return (
    <div className="py-2 w-full" data-testid={dataTestId}>
      {label && (
        <div className="flex items-center justify-between">
          <div className="flex items-center text-xs uppercase text-gray-500 pb-2 tracking-wide">
            <span>{label}</span>
            {infoTag && (
              <Tooltip
                title={
                  'Use this if you want to delay the milestone. The milestone will start a selected amount of time AFTER the selected trigger above.'
                }
                enterDelay={200}
                enterNextDelay={200}
              >
                <InformationCircleIcon className="text-blue-500 h-4 w-4 ml-2" />
              </Tooltip>
            )}
          </div>
          {labelTag}
        </div>
      )}
      {children}
    </div>
  )
}

const TemplateEditForm: FC<ImproverGoalMemberTemplateEditFormProps> = ({
  isLoading,
  selectedTemplate,
  setSelectedTemplateId,
  createGoalTemplate,
  updateGoalTemplate,
  trackTemplate,
  courseOptions,
  AccessControlList,
  isSaving,
  formFieldsChanged,
  setFormFieldsChanged,
  handleFormSave,
  setHandleFormSave,
}) => {
  const { currentUser } = useAuth()

  const isSuperAdmin = currentUser.roles.includes('SUPERADMIN')
  const activeClientIsStafflink =
    PROD_CLIENT.STAFFLINK === currentUser.membershipData.clientId

  // Form Fields
  const [templateFormFields, setTemplateFormFields] = useState<FormOptions>(
    configureFormFields(selectedTemplate, trackTemplate),
  )

  const confirmFieldChanges = useConfirm()

  const [selectedCourse, setSelectedCourse] =
    useState<LearnerCourseWithStorageObject>(
      getCurrentCourse(courseOptions, selectedTemplate),
    )

  // Handle Form Input Change
  const handleInputChange = (field: string, value: unknown) => {
    // changes detected
    setFormFieldsChanged(true)
    setTemplateFormFields((templateFormFields) => ({
      ...templateFormFields,
      [field]: value,
    }))
  }

  const handleDebounceRichTextEditorChange = (value?: string) => {
    handleInputChange('goalBody', value)
  }

  const getProgressTypeDefinition = (
    templateType: string,
    isMilestone: boolean,
  ) => {
    // Return the simple type definition for either GOAL or MILESTONE
    if (templateType === ProgressionTypes.simple) {
      if (isMilestone) {
        return ProgressionTypeDefinitions.simpleMilestone
      } else {
        return ProgressionTypeDefinitions.simpleGoal
      }
    }

    // Return the custom type definition for either GOAL or MILESTONE
    if (templateType === ProgressionTypes.custom) {
      if (isMilestone) {
        return ProgressionTypeDefinitions.customMilestone
      } else {
        return ProgressionTypeDefinitions.customGoal
      }
    }

    // Return the template type
    return ProgressionTypeDefinitions[templateType]
  }

  // Form Toggles
  // This controls visible fields
  const templateIsMilestone = templateFormFields.parentGoalId !== null
  const templateHasMilestones =
    templateFormFields?.templateType === ProgressionTypes.milestone
  const templateHasCustomRange =
    templateFormFields?.templateType === ProgressionTypes.custom
  const templateHasLearnerItem =
    templateFormFields?.templateType === ProgressionTypes.learner

  // Find the milestone select options
  const allMilestoneSelectOptions = templateIsMilestone
    ? getMilestoneOptions(trackTemplate, templateFormFields?.id)
    : []

  const milestoneStartTriggerByMilestone =
    templateIsMilestone &&
    (templateFormFields?.startDateTrigger ===
      StartEndTriggerTypes.milestoneStart ||
      templateFormFields?.startDateTrigger ===
        StartEndTriggerTypes.milestoneEnd)

  // BROKEN TEMPLATE ARE ALWAYS IN FIRST ARRAY ITEM
  // a broken template will return 0 as a date difference and always return in the first array element
  const activeMilestoneOnParent =
    trackTemplate?.orderedMilestones[0]?.items.filter((template) => {
      return template?.id === selectedTemplate?.id
    })

  // Check if the template returned a broken status
  const templateIsBroken =
    templateIsMilestone &&
    activeMilestoneOnParent[0]?.brokenTemplateLoop === true

  const progressionTypeDefinition = getProgressTypeDefinition(
    templateFormFields?.templateType,
    templateIsMilestone,
  )

  // Create object with all categories by ID and name
  const visibleLearnerCategoriesArr = courseOptions
    .map(
      (course) =>
        course?.learnerCategory?.id && [
          course?.learnerCategory?.id,
          course?.learnerCategory?.name,
        ],
    )
    .filter(Boolean)

  const visibleLearnerCategories = Object.fromEntries(
    visibleLearnerCategoriesArr,
  )

  // Get all the milestone options
  const allMilestones = {}

  if (trackTemplate?.orderedMilestones) {
    for (const dayObject of trackTemplate?.orderedMilestones || []) {
      for (const milestone of dayObject?.items || []) {
        allMilestones[milestone?.id] = milestone
      }
    }
  }

  const linkedMilestoneExists =
    allMilestones[templateFormFields?.startDateTriggerMilestoneId]

  let milestoneStartDateTriggerType = templateFormFields?.startDateTrigger

  // IF the milestone is triggered by another milestone AND
  // IF the linked milestone could not be found AND
  // IF there are no other options to show

  if (
    milestoneStartTriggerByMilestone &&
    !linkedMilestoneExists &&
    allMilestoneSelectOptions.length === 0
  ) {
    milestoneStartDateTriggerType = StartEndTriggerTypes.objective
  }

  useEffect(() => {
    if (handleFormSave) {
      updateGoalTemplate(templateFormFields)
    }
    setHandleFormSave(false)
  }, [handleFormSave])

  // if the user selects a different goal and there are form changes
  // prompt the user to save the changes
  useEffect(() => {
    if (formFieldsChanged) {
      confirmFieldChanges({
        title: 'You have unsaved changes',
        description: 'Do you want to save?',
        confirmationText: 'Save and continue',
        cancellationText: 'Continue without saving',
      })
        .then(() => {
          // save the goal
          updateGoalTemplate(templateFormFields)
        })
        .catch(() => {
          // do nothing
        })
    }
    setFormFieldsChanged(false)
  }, [selectedTemplate])

  // Selected Template Changed
  useEffect(() => {
    // Reset form fields
    const newTemplateFormFields = configureFormFields(
      selectedTemplate,
      trackTemplate,
    )
    setTemplateFormFields(newTemplateFormFields)
    setSelectedCourse(getCurrentCourse(courseOptions, selectedTemplate))
  }, [selectedTemplate])

  return (
    <div className="p-4 pb-20">
      <FormInputRow label="Title">
        <DebounceTextInput
          onChange={(value) => {
            return value !== templateFormFields.goalTitle
              ? handleInputChange('goalTitle', value)
              : null
          }}
          defaultValue={templateFormFields.goalTitle}
          name="goalTitle"
          setKey={templateFormFields.id + 'goalTitle'}
          isRequired={true}
          dataTestId="goal-template-edit-title"
        />
      </FormInputRow>
      {activeClientIsStafflink &&
        !templateFormFields?.parentGoalId &&
        (isSuperAdmin || templateFormFields.isGlobal) && (
          <FormInputRow
            label="Visibility"
            labelTag={
              templateFormFields.isGlobal && (
                <Tag color={'green'} globalThis>
                  Global
                </Tag>
              )
            }
          >
            <>
              <div className="px-3 pt-0 border border-gray-300 rounded">
                <div className="flex flex-row items-center justify-between py-1">
                  <span>Global</span>

                  <Switch
                    disabled={!isSuperAdmin}
                    checked={templateFormFields.isGlobal}
                    name="goalGlobal"
                    onChange={(event) => {
                      handleInputChange('isGlobal', event.target.checked)
                    }}
                  />
                </div>
                {templateFormFields.isGlobal && (
                  <div className="pb-3">
                    <FormInputRow label="User Role">
                      <Autocomplete<GlobalRole, true, true, false>
                        className="w-full bg-white pb-3"
                        fullWidth
                        size="small"
                        value={templateFormFields?.templateUserRoles}
                        disabled={!isSuperAdmin}
                        disableCloseOnSelect
                        filterSelectedOptions
                        multiple
                        onChange={(_event, newValue) => {
                          handleInputChange('templateUserRoles', newValue)
                        }}
                        id="dialogInputRolesAll"
                        options={allRoles}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormInputRow>
                    <AccessControlList />
                  </div>
                )}
              </div>
            </>
          </FormInputRow>
        )}
      <FormInputRow
        label="Description"
        dataTestId="templates-edit-description-form"
      >
        <RichTextEditor
          name="templateDescription"
          id="templateDescription"
          defaultValue={templateFormFields.goalBody}
          onChange={handleDebounceRichTextEditorChange}
          debounce={800}
          className="max-h-[200px]"
        />
      </FormInputRow>
      <FormSpacer />
      <Accordion title="Type" expanded={true}>
        <FormInputRow label="Progress Type">
          <>
            <Select
              disabled={isSaving}
              value={templateFormFields?.templateType}
              key={templateFormFields.id + 'templateType'}
              onChange={(event) => {
                return event.target.value !== templateFormFields?.templateType
                  ? handleInputChange('templateType', event.target.value)
                  : null
              }}
              size={'small'}
              fullWidth={true}
            >
              <MenuItem value={ProgressionTypes.simple}>
                {templateIsMilestone
                  ? ProgressionTypesLabels.simpleMilestone
                  : ProgressionTypesLabels.simpleGoal}
              </MenuItem>
              <MenuItem value={ProgressionTypes.custom}>
                {templateIsMilestone
                  ? ProgressionTypesLabels.customMilestone
                  : ProgressionTypesLabels.customGoal}
              </MenuItem>
              {!templateIsMilestone && (
                <MenuItem value={ProgressionTypes.milestone}>
                  {ProgressionTypesLabels.milestone}
                </MenuItem>
              )}

              {templateIsMilestone && (
                <MenuItem key={4} value={ProgressionTypes.learner}>
                  {ProgressionTypesLabels.learner}
                </MenuItem>
              )}
            </Select>
            <p className="pt-3 text-gray-400 text-sm px-2">
              {progressionTypeDefinition}
            </p>
          </>
        </FormInputRow>

        {templateHasCustomRange && (
          <FormInputRow>
            <div className="grid grid-cols-2 pt-4 gap-4">
              <div>
                <p className="text-xs uppercase text-gray-400 pb-1">
                  Start Value
                </p>
                <DebounceNumberInput
                  onChange={(value) => {
                    return value !== templateFormFields?.startValue
                      ? handleInputChange('startValue', value)
                      : null
                  }}
                  defaultValue={templateFormFields?.startValue}
                  name="startValue"
                  setKey={templateFormFields.id + 'startValue'}
                  step={1}
                />
              </div>
              <div>
                <p className="text-xs uppercase text-gray-400 pb-1">
                  Target Value
                </p>
                <DebounceNumberInput
                  onChange={(value) => {
                    return value !== templateFormFields?.targetValue
                      ? handleInputChange('targetValue', value)
                      : null
                  }}
                  defaultValue={templateFormFields?.targetValue}
                  name="targetValue"
                  setKey={templateFormFields.id + 'targetValue'}
                  step={1}
                />
              </div>
            </div>
          </FormInputRow>
        )}

        {templateHasLearnerItem && (
          <>
            <FormSpacer />
            <FormInputRow
              label={
                <Tooltip
                  title={
                    'Please ensure the course has the required permissions to allow visibility for Users and Groups'
                  }
                  enterDelay={200}
                  enterNextDelay={200}
                >
                  <div className=" flex items-center">
                    Learner Course
                    <InformationCircleIcon className="text-blue-500 h-4 w-4 ml-2" />
                  </div>
                </Tooltip>
              }
            >
              <Autocomplete
                disabled={isSaving}
                id="learnerMilestoneSelectCourse"
                size="small"
                key={templateFormFields?.id}
                options={courseOptions}
                groupBy={(option: LearnerCourseWithStorageObject) =>
                  option.learnerCategory.id.toString()
                }
                renderGroup={(params) => {
                  return (
                    <li key={params.key}>
                      <p className="p-2 px-4 bg-gray-100 text-gray-600 italic">
                        {visibleLearnerCategories[params.group]}
                      </p>
                      <ul>{params.children}</ul>
                    </li>
                  )
                }}
                getOptionLabel={(option: LearnerCourseWithStorageObject) =>
                  (option.learnerCategory.isGlobal ? '(Global) ' : '') +
                  option.name
                }
                renderOption={(
                  props,
                  option: LearnerCourseWithStorageObject,
                ) => {
                  return (
                    <li {...props}>
                      {option.learnerCategory.isGlobal && (
                        <GlobeAltIcon
                          className={'-ml-0.5 mr-1.5 h-5 w-5 text-green-400'}
                        />
                      )}
                      {option.name}
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Course" />
                )}
                value={selectedCourse}
                onChange={(_, newValue: LearnerCourseWithStorageObject) => {
                  setSelectedCourse(newValue)
                  handleInputChange('attachedLearnerItemId', newValue?.id)
                }}
                isOptionEqualToValue={(
                  option: LearnerCourseWithStorageObject,
                  value: LearnerCourseWithStorageObject,
                ) => {
                  return option.id === value?.id
                }}
              />
            </FormInputRow>
            <FormSpacer />
            {!templateFormFields?.attachedLearnerItemId && (
              <div className="p-10 bg-gray-100 rounded text-gray-400 grid place-items-center">
                No Course Selected
              </div>
            )}
            {templateFormFields?.attachedLearnerItemId && (
              <CoursePreview learnerCourse={selectedCourse} />
            )}
          </>
        )}
      </Accordion>
      {templateHasMilestones && !templateIsMilestone && (
        <>
          <FormSpacer size={3} />
          <Accordion title="Milestones" expanded={true}>
            {!templateFormFields?.childGoals.length && (
              <p className="text-sm pt-2 text-gray-400">
                No Milestones have been set.
              </p>
            )}
            <div className="flex flex-col gap-2">
              <FormSpacer size={1} />
              {trackTemplate?.orderedMilestones.map((dayObj) => {
                return dayObj?.items.map((milestone) => {
                  return (
                    <Button
                      key={milestone.id}
                      variant="outlined"
                      className="gap-4 text-left flex items-center justify-between !border-gray-300 hover:!bg-gray-100 !text-gray-500"
                      size="small"
                      fullWidth={true}
                      onClick={() => {
                        setSelectedTemplateId(milestone.id)
                      }}
                    >
                      {milestone?.goalTitle}
                      <PencilIcon className="w-4 h-4" />
                    </Button>
                  )
                })
              })}
            </div>
            <FormSpacer size={3} />
            <Button
              loading={isLoading}
              onClick={() => {
                createGoalTemplate(templateFormFields?.id)
              }}
            >
              Add a Milestone
            </Button>
          </Accordion>
        </>
      )}
      <FormSpacer />
      {templateIsMilestone && (
        <>
          <Accordion title="Start Point" expanded={true}>
            <FormInputRow label="Trigger">
              <>
                <Select
                  disabled={isSaving}
                  value={milestoneStartDateTriggerType}
                  key={templateFormFields.id + 'startDateTrigger'}
                  onChange={(event) => {
                    return event.target.value !==
                      templateFormFields?.startDateTrigger
                      ? handleInputChange(
                          'startDateTrigger',
                          event.target.value,
                        )
                      : null
                  }}
                  size={'small'}
                  fullWidth={true}
                >
                  <MenuItem value={StartEndTriggerTypes.objective}>
                    {StartEndTriggerTypeTitles.objectiveStart}
                  </MenuItem>
                  {allMilestoneSelectOptions.length && (
                    <MenuItem value={StartEndTriggerTypes.milestoneStart}>
                      {StartEndTriggerTypeTitles.milestoneStart}
                    </MenuItem>
                  )}
                  {allMilestoneSelectOptions.length && (
                    <MenuItem value={StartEndTriggerTypes.milestoneEnd}>
                      {StartEndTriggerTypeTitles.milestoneEnd}
                    </MenuItem>
                  )}
                </Select>
                <p className="pt-3 text-gray-400 text-sm px-2">
                  {allMilestoneSelectOptions.length > 0
                    ? StartEndTriggerTypesDefinitions[
                        templateFormFields?.startDateTrigger
                      ]
                    : StartEndTriggerTypesDefinitions.objective}
                </p>
              </>
            </FormInputRow>

            {milestoneStartTriggerByMilestone &&
              allMilestoneSelectOptions.length > 0 && (
                <>
                  <FormSpacer />
                  <FormInputRow label="Milestone">
                    <Select
                      value={templateFormFields?.startDateTriggerMilestoneId}
                      key={
                        templateFormFields.id + 'startDateTriggerMilestoneId'
                      }
                      onChange={(event) => {
                        return event.target.value !==
                          templateFormFields?.startDateTriggerMilestoneId
                          ? handleInputChange(
                              'startDateTriggerMilestoneId',
                              event.target.value,
                            )
                          : null
                      }}
                      size={'small'}
                      fullWidth={true}
                    >
                      {allMilestoneSelectOptions.map((selectOption) => {
                        return (
                          <MenuItem
                            className="max-w-[366px] whitespace-normal"
                            value={selectOption?.id}
                            key={selectOption?.id}
                          >
                            {selectOption?.goalTitle}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormInputRow>
                  {templateIsBroken && (
                    <>
                      <FormSpacer />
                      <p className="p-4 rounded bg-red-50 text-red-500 text-sm flex gap-4 flex-row items-start">
                        <ExclamationTriangleIcon className="h-5 w-5 mt-1.5 flex-none text-red-600" />
                        {ErrorTypes.loop}
                      </p>
                    </>
                  )}
                </>
              )}

            <FormSpacer />

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <FormInputRow label="Delay" infoTag={'Test'}>
                  <DebounceNumberInput
                    step={1}
                    disableNegatives={true}
                    onChange={(value) => {
                      const returnVal: number = !isNaN(value) ? value : 0
                      return value !== templateFormFields?.startDateNumber
                        ? handleInputChange('startDateNumber', returnVal)
                        : null
                    }}
                    defaultValue={templateFormFields?.startDateNumber}
                    name="startDateNumber"
                    setKey={templateFormFields.id + 'startDateNumber'}
                  />
                </FormInputRow>
              </div>
              <FormInputRow label="Unit">
                <Select
                  disabled={isSaving}
                  value={templateFormFields?.startDateFormat}
                  key={templateFormFields.id + 'startDateFormat'}
                  onChange={(event) => {
                    return event.target.value !==
                      templateFormFields?.startDateFormat
                      ? handleInputChange('startDateFormat', event.target.value)
                      : null
                  }}
                  size={'small'}
                  fullWidth={true}
                  className="capitalize"
                >
                  <MenuItem value={DateTypes.day} className="capitalize">
                    {DateTypes.day + 's'}
                  </MenuItem>
                  <MenuItem value={DateTypes.week} className="capitalize">
                    {DateTypes.week + 's'}
                  </MenuItem>
                  <MenuItem value={DateTypes.month} className="capitalize">
                    {DateTypes.month + 's'}
                  </MenuItem>
                  <MenuItem value={DateTypes.year} className="capitalize">
                    {DateTypes.year + 's'}
                  </MenuItem>
                </Select>
              </FormInputRow>
            </div>
          </Accordion>

          <FormSpacer />
        </>
      )}
      <FormSpacer />
      <Accordion title="Date Settings" expanded={true}>
        <div className="grid grid-cols-2 gap-4">
          <FormInputRow
            label={
              <Tooltip
                title={
                  'The period of time from starting the goal, to the target end date.'
                }
                enterDelay={200}
                enterNextDelay={200}
              >
                <div>Expected Duration</div>
              </Tooltip>
            }
          >
            <DebounceNumberInput
              step={1}
              disableNegatives={true}
              onChange={(value) => {
                const returnVal: number = !isNaN(value) ? value : 0
                return value !== templateFormFields?.dueDateNumber
                  ? handleInputChange('dueDateNumber', returnVal)
                  : null
              }}
              defaultValue={templateFormFields?.dueDateNumber}
              name="dueDateNumber"
              setKey={templateFormFields.id + 'dueDateNumber'}
            />
          </FormInputRow>
          <FormInputRow label="Unit">
            <Select
              disabled={isSaving}
              value={templateFormFields?.dueDateFormat}
              key={templateFormFields.id + 'dueDateFormat'}
              onChange={(event) => {
                return event.target.value !== templateFormFields?.dueDateFormat
                  ? handleInputChange('dueDateFormat', event.target.value)
                  : null
              }}
              size={'small'}
              fullWidth={true}
              className="capitalize"
            >
              <MenuItem value={DateTypes.day} className="capitalize">
                {DateTypes.day + 's'}
              </MenuItem>
              <MenuItem value={DateTypes.week} className="capitalize">
                {DateTypes.week + 's'}
              </MenuItem>
              <MenuItem value={DateTypes.month} className="capitalize">
                {DateTypes.month + 's'}
              </MenuItem>
              <MenuItem value={DateTypes.year} className="capitalize">
                {DateTypes.year + 's'}
              </MenuItem>
            </Select>
          </FormInputRow>
        </div>
      </Accordion>
    </div>
  )
}

export default TemplateEditForm
