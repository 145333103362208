import { FC } from 'react'
export const LearnerStatItem: FC<{
  icon: JSX.Element
  value: number
  label: string
}> = ({ icon, value, label }) => {
  return (
    <div className="border border-gray-200 p-2 gap-0 text-center w-full flex flex-col items-center justify-around rounded">
      {icon}
      <p className="text-md font-bold">{value}</p>
      <p className="text-xs bold text-indigo-600 uppercase">{label}</p>
    </div>
  )
}
