import { useEffect, useState } from 'react'

import { Input } from '@mui/material'

import useDebounce from '../../lib/hooks/UseDebounce'

interface DebouncedNumberProps {
  defaultValue: number
  onChange: (event) => void
  debounceTimeout?: number
  name: string
  setKey: string | number
  step?: number
  disableNegatives?: boolean
  disabled?: boolean
}

const DebounceNumberInput = ({
  defaultValue,
  onChange,
  debounceTimeout,
  name,
  setKey,
  step = 0.01,
  disableNegatives = false,
  disabled = false,
}: DebouncedNumberProps) => {
  // Extra CSS styling for disabled input
  const disabledClasses = 'bg-gray-100 select-none hover:!border-gray-400'

  // Local state for input

  const [value, setValue] = useState<string>(defaultValue + '' || '0')

  // Debounce value with timeout
  const debouncedValue = useDebounce<number>(
    parseFloat(value),
    debounceTimeout || 750,
  )

  // Handle the input change
  const handleChange = (value: string) => {
    setValue(value)
  }

  // When the debounce is complete, update the value
  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue])

  // When the default value changes, update the value
  useEffect(() => {
    setValue(defaultValue + '')
  }, [defaultValue])

  return (
    <Input
      className={`border border-gray-400 hover:border-gray-600 rounded ${
        disabled && disabledClasses
      } `}
      key={setKey}
      id={` ${name}-goal-input `}
      size="small"
      name={name}
      fullWidth
      disableUnderline={true}
      disabled={disabled}
      value={value}
      inputProps={{
        step: step,
        min: disableNegatives ? 0 : -999999999999,
        max: 999999999999,
        type: 'number',
        pattern: '^-?\\d*.{0,1}\\d+$',
        className: 'p-2 px-3 rounded focus:ring-[1px] focus:ring-blue-600',
      }}
      onChange={(e) => {
        handleChange(e.target.value)
      }}
      required
    />
  )
}

export default DebounceNumberInput
